function initMap() {
    var styles = [{"featureType":"administrative","elementType":"labels","stylers":[{"visibility":"on"},{"gamma":"1.82"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"gamma":"1.96"},{"lightness":"-9"}]},{"featureType":"administrative","elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"on"},{"lightness":"25"},{"gamma":"1.00"},{"saturation":"-100"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"hue":"#ffaa00"},{"saturation":"-43"},{"visibility":"on"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"simplified"},{"hue":"#ffaa00"},{"saturation":"-70"}]},{"featureType":"road.highway.controlled_access","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"visibility":"on"},{"saturation":"-100"},{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"saturation":"-100"},{"lightness":"40"},{"visibility":"off"}]},{"featureType":"transit.station.airport","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"gamma":"0.80"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"off"}]}]
    var options = {
        mapTypeControlOptions: {
            mapTypeIds: ['Styled']
        },
        center: new google.maps.LatLng(52.4204, 16.9441),
	zoom: 13,
	disableDefaultUI: true, 
	mapTypeId: 'Styled',
        scrollwheel: false,
        zoomControl: true
    };
    var div = document.getElementById('map');
    var map = new google.maps.Map(div, options);
    var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
    map.mapTypes.set('Styled', styledMapType);
    var iconBase = '../../wp-content/themes/hunting/assets/images/marker.png';
    var marker = new google.maps.Marker({
        position: {lat: 52.4204, lng: 16.9441},
	map: map,
	icon: iconBase
    });
}

function initMap2() {
    var styles = [{"featureType":"administrative","elementType":"labels","stylers":[{"visibility":"on"},{"gamma":"1.82"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"gamma":"1.96"},{"lightness":"-9"}]},{"featureType":"administrative","elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"on"},{"lightness":"25"},{"gamma":"1.00"},{"saturation":"-100"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"hue":"#ffaa00"},{"saturation":"-43"},{"visibility":"on"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"simplified"},{"hue":"#ffaa00"},{"saturation":"-70"}]},{"featureType":"road.highway.controlled_access","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"visibility":"on"},{"saturation":"-100"},{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"saturation":"-100"},{"lightness":"40"},{"visibility":"off"}]},{"featureType":"transit.station.airport","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"gamma":"0.80"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"off"}]}]
    var options = {
        mapTypeControlOptions: {
            mapTypeIds: ['Styled']
        },
        center: new google.maps.LatLng(52.4204, 16.9441),
	zoom: 11,
	disableDefaultUI: true, 
	mapTypeId: 'Styled',
        scrollwheel: false,
        zoomControl: true
    };
    var div = document.getElementById('map');
    var map = new google.maps.Map(div, options);
    var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
    map.mapTypes.set('Styled', styledMapType);
    var iconBase_biuro = '../../wp-content/themes/hunting/assets/images/marker/pl.png';
    var iconBase_biuro_en = '../../wp-content/themes/hunting/assets/images/marker/en.png';
    var iconBase_biuro_de = '../../wp-content/themes/hunting/assets/images/marker/de.png';
    var iconBase_biuro_fr = '../../wp-content/themes/hunting/assets/images/marker/fr_nl.png';
    var iconBase_biuro_dk = '../../wp-content/themes/hunting/assets/images/marker/dk.png';
    var iconBase_biuro_nl = '../../wp-content/themes/hunting/assets/images/marker/fr_nl.png';
    
    
    if(jQuery('html').attr('lang')==='en-GB'){
        var marker = new google.maps.Marker({
            position: {lat: 52.4204, lng: 16.9441},
            map: map,
            icon: iconBase_biuro_en
        });

    } else if(jQuery('html').attr('lang')==='de-DE'){
        var marker = new google.maps.Marker({
            position: {lat: 52.4204, lng: 16.9441},
            map: map,
            icon: iconBase_biuro_de
        });
    } else if(jQuery('html').attr('lang')==='fr-FR'){
        var marker = new google.maps.Marker({
            position: {lat: 52.4204, lng: 16.9441},
            map: map,
            icon: iconBase_biuro_fr
        });
    } else if(jQuery('html').attr('lang')==='da-DK'){
        var marker = new google.maps.Marker({
            position: {lat: 52.4204, lng: 16.9441},
            map: map,
            icon: iconBase_biuro_dk
        });
    } else if(jQuery('html').attr('lang')==='nl-NL'){
        var marker = new google.maps.Marker({
            position: {lat: 52.4204, lng: 16.9441},
            map: map,
            icon: iconBase_biuro_nl
        });
    } else {
        var marker = new google.maps.Marker({
            position: {lat: 52.4204, lng: 16.9441},
            map: map,
            icon: iconBase_biuro
        });
    }
    
    
    
}